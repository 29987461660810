@import "./keyframes.scss";

.ori-card-loading {
    border-radius: 2px;
    background: linear-gradient(90deg, rgba(#999999, 0.1), rgba(#999999, 0.3), rgba(#999999, 0.1));
    background-size: 600% 600%;

    @include animation(ori-card-loading 1.5s ease infinite);
}

.ori-animated {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.ori-infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.ori-fade-in {
    @include animation(ori-fade-in 1s);
}

.ori-fade-in-left {
    @include animation(ori-fade-in-left 1s);
}

.ori-fade-in-right {
    @include animation(ori-fade-in-right 1s);
}

// .ori-fade-in-up {
//     @include animation(ori-fade-in-up 1s);
// }

.ori-fade-out {
    @include animation(ori-fade-out 1s);
}

// .ori-fade-out-left {
//     @include animation(ori-fade-out-left 1s);
// }

// .ori-fade-out-right {
//     @include animation(ori-fade-out-right 1s);
// }

// .ori-fade-out-down {
//     @include animation(ori-fade-out-down 1s);
// }

.ori-fade-out-up {
    @include animation(ori-fade-out-up 0.5s);
}

.ori-zoom-in {
    @include animation(ori-zoom-in 1s);
}

// .ori-zoom-out {
//     @include animation(ori-zoom-out 1s);
// }

.ori-translate-down {
    @include animation(ori-translate-down 0.5s);
}
